<template>
	<div class="app">
		<AppHeader fixed>
			<SidebarToggler class="d-lg-none" display="md" mobile />
			<b-link class="navbar-brand" to="/dashboard">
				<img class="navbar-brand-full" src="img/logo.png" width="90" height="43" alt="ARES Logo" />
				<img class="navbar-brand-minimized" src="img/logo-sygnet.png" width="32" height="32" alt="ARES Logo" />
			</b-link>
			<SidebarToggler class="d-md-down-none" display="lg" />
			<b-navbar-nav class="ml-auto">
				<Notifications />
				<DefaultHeaderDropdownAccnt :username="username" :userCompany="userCompany" />
			</b-navbar-nav>
		</AppHeader>
		<div class="app-body">
			<AppSidebar fixed class="sidenav">
				<SidebarHeader />
				<SidebarForm />
				<SidebarNav :navItems="nav"></SidebarNav>
				<SidebarFooter />
				<SidebarMinimizer />
			</AppSidebar>
			<main class="main">
				<div class="container-fluid">
					<router-view></router-view>
				</div>
			</main>
		</div>
		<TheFooter class="footer">
			<!--footer-->
			<div class="ml-auto">
				<a href="https://www.ayun.ph" class="footer-text">Ayun! Asset-Based</a>
				<span class="ml-1">&copy; {{ currentYear }}
					<img src="img/tawitech_logo_white.png" width="auto" height="35" alt="TAWItech Logo" />
				</span>
			</div>
		</TheFooter>

		<!-- Modals -->
		<UpdateCredentials />
	</div>
</template>

<script>
import managerNav from '@/containers/menu/_managerNav';
import scannerNav from '@/containers/menu/_scannerNav';
import {
	Header as AppHeader,
	SidebarToggler,
	Sidebar as AppSidebar,
	SidebarFooter,
	SidebarForm,
	SidebarHeader,
	SidebarMinimizer,
	SidebarNav,
	Aside as AppAside,
	AsideToggler,
	Footer as TheFooter,
	Breadcrumb,
} from '@coreui/vue';
import DefaultHeaderDropdownAccnt from './DefaultHeaderDropdownAccnt';
import Notifications from '../views/commons/notification/Notifications';
import UpdateCredentials from '@/views/account/UpdateCredentials';
import _ from 'lodash';

export default {
	name: 'full',
	components: {
		AsideToggler,
		AppHeader,
		AppSidebar,
		AppAside,
		TheFooter,
		Breadcrumb,
		DefaultHeaderDropdownAccnt,
		SidebarForm,
		SidebarFooter,
		SidebarToggler,
		SidebarHeader,
		SidebarNav,
		SidebarMinimizer,
		Notifications,
		UpdateCredentials,
	},
	data() {
		return {
			nav: [],

			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,

			isManager: this.$store.getters.isManager,
			isViewer: this.$store.getters.isViewer,
			isScanner: this.$store.getters.isScanner,
		};
	},
	created() {
		this.nav = this.isScanner ? scannerNav.items : managerNav.items;
	},
	computed: {
		currentYear() {
			return new Date().getFullYear();
		},
		name() {
			return this.$route.name;
		},
		list() {
			return this.$route.matched.filter(
				(route) => route.name || route.meta.label
			);
		},
		username() {
			if (this.$store.getters.email) {
				return this.$store.getters.email;
			}
			return '';
		},
		userCompany() {
			if (this.$store.getters.email) {
				return this.$store.getters.loggedUser.company;
			}
			return '';
		},
		isNewUser() {
			const currUser = this.$store.getters.loggedUser;
			if (!_.isEmpty(currUser)) {
				return currUser.isNewUser;
			}
			return false;
		}
	},
	async mounted() {
		// if a superadmin user trying to access a non-admin page return to admin dashboard
		if (this.$store.getters.isSuperAdmin) {
			this.$router.push('/admin/dashboard');
			this.$toaster.warning('You are not allowed to access this page.');
		} else if (this.isNewUser) {
			this.$bvModal.show('update-credentials');
		}

		this.nav = await this.filterNavigations();
	},
	methods: {
		async filterNavigations() {
			let filteredNav = this.nav;
			let permissions = this.loggedUserCompany.permissions ? this.loggedUserCompany.permissions : {};

			if (_.isEmpty(permissions)) {
				this.$toaster.warning('You are not allowed to access this page.');
				await this.$store.dispatch('logout');

				let previousState = this.$store.getters.previousState;
				if (!_.isEmpty(previousState)) {
					this.$store.replaceState(previousState);
				}

				this.$router.push('/login');
				return;
			}

			if (!permissions.cms) {
				filteredNav = this.removeAccountSetupAccess(filteredNav);
			}

			if (!permissions.inventory && !permissions.dispatch) {
				// Asset Management
				this.removePageAccess(filteredNav, 'Asset Management', 'Asset');
				this.removePageAccess(filteredNav, 'Asset Management', 'Asset History Log');

				// Reports
				this.removePageAccess(filteredNav, 'Reports', 'Asset Pool Distribution');
				this.removePageAccess(filteredNav, 'Reports', 'Asset Accountability');
				this.removePageAccess(filteredNav, 'Reports', 'Inventory Summary');

				// Data Importer
				this.removePageAccess(filteredNav, 'Data Importer', 'Asset Importer');
			}

			if (!permissions.inventory) {
				// Asset Management
				this.removePageAccess(filteredNav, 'Asset Management', 'Asset Issuance');

				// Transactions
				this.removePageAccess(filteredNav, 'Transactions', 'Inventory Session');

				// Reports
				this.removePageAccess(filteredNav, 'Reports', 'Asset Recon');
				this.removePageAccess(filteredNav, 'Reports', 'Inventory Compliance');
				this.removePageAccess(filteredNav, 'Reports', 'Inventory Scan Progress');
				this.removePageAccess(filteredNav, 'Reports', 'User Inventory Scans');
			}

			if (!permissions.dispatch) {
				// Account Setup
				this.removePageAccess(filteredNav, 'Account Setup', 'Connection');
				this.removePageAccess(filteredNav, 'Account Setup', 'Transportation');

				// Transactions
				this.removePageAccess(filteredNav, 'Transactions', 'Dispatch');
				this.removePageAccess(filteredNav, 'Transactions', 'Receipt');

				// Reports
				this.removePageAccess(filteredNav, 'Reports', 'Assets With Truckers');

				// Data Importer
				this.removePageAccess(filteredNav, 'Data Importer', 'Dispatch Importer');
				this.removePageAccess(filteredNav, 'Data Importer', 'Transportation Importer');
			}

			if (!permissions.sticker) {
				// Asset Management
				this.removePageAccess(filteredNav, 'Asset Management', 'Asset Sticker Generator');
			}

			if (this.isViewer) {
				filteredNav = this.removeDataImporterAccess(filteredNav);
			}

			if (!this.isManager) {
				// Support
				this.removePageAccess(filteredNav, 'Support', 'User Session Logs');
			}

			return filteredNav;
		},

		removeAccountSetupAccess(filteredNav) {
			// Account Setup
			this.removePageAccess(filteredNav, 'Account Setup', 'Company');
			this.removePageAccess(filteredNav, 'Account Setup', 'Storage Location');
			this.removePageAccess(filteredNav, 'Account Setup', 'User');
			this.removePageAccess(filteredNav, 'Account Setup', 'Connection');
			this.removePageAccess(filteredNav, 'Account Setup', 'Transportation');
			this.removePageAccess(filteredNav, 'Account Setup', 'Asset Type');

			return filteredNav;
		},
		removeDataImporterAccess(filteredNav) {
			// Data Importer
			this.removePageAccess(filteredNav, 'Data Importer', 'Asset Importer');
			this.removePageAccess(filteredNav, 'Data Importer', 'Company Importer');
			this.removePageAccess(filteredNav, 'Data Importer', 'Dispatch Importer');
			this.removePageAccess(filteredNav, 'Data Importer', 'Location Importer');
			this.removePageAccess(filteredNav, 'Data Importer', 'Transportation Importer');
			this.removePageAccess(filteredNav, 'Data Importer', 'User Importer');

			return filteredNav;
		},

		removePageAccess(filteredNav, sectionTitle, pageTitle) {
			let index = _.findIndex(filteredNav, (o) => {
				return o.name === sectionTitle;
			});

			let selNav = filteredNav[index];
			if (selNav) {
				// Remove Page from the Section
				selNav.children = _.filter(selNav.children, (o) => {
					return o.name !== pageTitle;
				});

				// Remove Section for empty page options
				if (_.isEmpty(selNav.children)) {
					_.remove(filteredNav, (o) => {
						return o.name === sectionTitle;
					});
				}
			}
		},
	},
};
</script>
<style>
.list {
    color: #122c91;
}
</style>
